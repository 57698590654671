import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import CtaSection from "../components/CtaSection";
import TwoColumnSection from "../components/TwoColumnSection";

const Page = ({ data }) => (
	<Layout
		pageMeta={{
			title: "Automated email sequences – Sidemail",
		}}>
		<div>
			<section className="aboveFold">
				<div className="container">
					<h1>
						<div className="text-xsmall uptitle mb-20 text-light">
							Automated email sequences
						</div>
						<div className="maxWidth-750">
							Set up onboarding email sequence & more
						</div>
					</h1>

					<p className="maxWidth-750 text-large text-dark itemCenter mb-30">
						Set up email automation to deliver your email or sequence of emails
						when a defined event happens — for example, user sign-up. Use it to
						onboard new users, convert them into paying customers, and minimize
						churn.
					</p>

					<a
						className="button button--bright button--huge mb-70"
						href="https://client.sidemail.io/register"
						style={{ minWidth: 300 }}>
						Start 7-day trial
					</a>

					<div className="videoContainer">
						<video loop autoPlay playsInline muted height={300}>
							<source src="/assets/automation.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Automation triggers"
					text="Automatically send messages to users' based on what they are doing or not doing in your application. Set up an automation trigger and exit‑goal when contact properties meet the correct conditions."
					list={[
						"Conditional‑based triggers and exit‑goal",
						"Lightweight and simple to set up",
					]}
					img={data.automationTriggersImage.childImageSharp.fluid}
				/>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Email sequences"
					text={
						<>
							<p className="text">
								Sometimes even an important message slips through the user's
								inbox. Get ahead and create an email sequence so it never
								happens again.
							</p>

							<p className="text">
								This is perfect for SaaS onboarding, boosting your trial-to-paid
								conversion, re-engaging inactive users, and so much more.
							</p>
						</>
					}
					list={[
						"As many emails in the sequence as you need",
						"Time delay between emails in the sequence",
						"Timezone-based delivery",
					]}
					img={data.automationSequenceImage.childImageSharp.fluid}
				/>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Contact profiles"
					text="Send your users' data to Sidemail with API and learn more about your users. When was the last time Bob used your app? What's his current plan? When's the next billing date? To&#8209;dos created count? Get all the answers in a neatly organized contact profile."
					list={[
						"Enables targeted messages and automation",
						"The structure of contact properties is up to you",
					]}
					cta={<Link to="/contact-profiles">Learn more about contacts</Link>}
					img={data.contactProfilesImage.childImageSharp.fluid}
				/>
			</section>

			<section className="container">
				<TwoColumnSection
					title="No-code email editor"
					text="Create branded emails with your logo and matching your brand colors with simple-to-use, no-code email editor."
					list={[
						"Great design which fits your brand",
						"Supports all email clients",
						"Essential template elements",
					]}
					cta={<Link to="/no-code-email-editor">No-code email editor</Link>}
					img={data.noCodeEditorImage.childImageSharp.fluid}
				/>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Rich-text email editor"
					text="Create personal, content-focused emails with an efficient and pleasure-to-use text editor."
					list={[
						"Fast and easy to use",
						"Personalization with variables",
						"Emoji support 😻",
					]}
					img={data.richTextEditorImage.childImageSharp.fluid}
				/>
			</section>

			<CtaSection />
		</div>
	</Layout>
);

export default Page;

export const query = graphql`
	query {
		automationTriggersImage: file(
			relativePath: { eq: "sidemail-public--automation-triggers.png" }
		) {
			...twoColumnsImage
		}
		automationSequenceImage: file(
			relativePath: { eq: "sidemail-public--automation-sequence.png" }
		) {
			...twoColumnsImage
		}
		contactProfilesImage: file(
			relativePath: { eq: "sidemail-public--automation-contacts.png" }
		) {
			...twoColumnsImage
		}
		noCodeEditorImage: file(
			relativePath: { eq: "sidemail-public--api-nocode.png" }
		) {
			...twoColumnsImage
		}
		richTextEditorImage: file(
			relativePath: { eq: "sidemail-public--onetime-richtext.png" }
		) {
			...twoColumnsImage
		}
	}
`;
